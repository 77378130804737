import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import intlShape from '../../../schemas/intl';
import Alert from '../../../../components/atoms/Alert';
import {
  sendEvent,
  isLatestEventLabel,
} from '../../../utils/gtm';
import messages from '../../../utils/messages';
import SubmitBarFigures from '../Teleporter/SubmitBarFigures';
import getElementOffsetPosition from '../../../utils/get-element-offset-position';
import { useEmailSubmissionMutation } from '../../../store/reducers/api-call';
import style from './style';
import devices from '../../../styles/Devices';
import { brand } from '../../../localizations/current-locale';

const AlertWrapper = styled.div`
  && {
    margin-top: 2rem;

  ${(brand === 'omalaina') && css`
  width: 100%;
  margin: 30px auto;

  @media ${devices.upFromLargeTablet} {
    ${(brand === 'omalaina') && css`
      max-width: 67%;
    `}
    ${(brand === 'rahalaitos') && css`
      max-width: 85%;
    `}
  }
`}
}
`;

const EmailSubmissionSuccess = styled.span`
  && {
    display: block;
    margin-top: 1rm;
    text-align: center;
  }
`;

const TeleButton = styled.button`
  && {
    border: none;
    cursor: pointer;
    line-height: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    height: ${style.buttonHeight};
    box-shadow: ${style.boxShadow};
    padding: ${style.buttonPadding};
    margin-top: ${style.marginTop};
    color: ${style.buttonTextColor};
    width: ${style.buttonWidth};
    background: ${style.ctaButtonBackground};
    border-radius: ${style.buttonBorderRadius};
    margin-top: ${style.buttonMarginTop};

    @media ${devices.upFromTablet} {
      margin-top: ${style.buttonMarginTop};
    }

    &:disabled {
      opacity: 0.6;
      pointer-events: none;
      background: ${style.disabledBg};
      color: ${style.disabledColor};
    }
  }
`;

const SubmitButton = ({
  disableButton,
  submitSuccess,
  data,
  intl: { formatMessage },
  setShowErrors,
}) => {
  const formState = useSelector((state) => state.form.application);
  const [emailSubmissionSubmit, result] = useEmailSubmissionMutation();

  const {
    submitting,
    submitFailed,
    syncErrors,
    error,
  } = formState;

  const applicantIsPoliticallyExposedPerson = formState.values.applicant
  && formState.values.applicant.politicallyExposedPerson;
  const coApplicantIsPoliticallyExposedPerson = formState.hasCoApplicant
  && formState.values.coApplicant && formState.values.coApplicant.politicallyExposedPerson;

  const customerIsPEP = applicantIsPoliticallyExposedPerson || coApplicantIsPoliticallyExposedPerson; // eslint-disable-line

  const uncaughtRuntimeError = (
    error
    && error.message?.id === data.messages.uncaughtSubmitError.id
  ) || false;

  let submitError = null;
  const generalSubmitError = {
    id: messages.generalSubmitError.id,
    type: 'neutral',
    message: formatMessage(messages.generalSubmitError, {
      a: (chunks) => <a href="tel:0770220180">{chunks}</a>,
      i: (chunks) => <i>{chunks}</i>,
      phoneNumber: '0770 22 01 80',
      customerPhoneNumber: formState.values.applicant?.phoneNumber,
    }),
  };

  if (customerIsPEP) {
    submitError = {
      id: messages.applicationFormPoliticallyExposedPersonWarning.id,
      message: formatMessage(messages.applicationFormPoliticallyExposedPersonWarning),
    };
  } else if (error) {
    if (error.message.id === messages.generalSubmitError.id) {
      submitError = generalSubmitError;
    } else if (uncaughtRuntimeError) {
      submitError = {
        id: messages.uncaughtSubmitError.id,
        type: 'neutral',
        message: formatMessage(messages.uncaughtSubmitError, {
          i: (chunks) => <i>{chunks}</i>,
          customerPhoneNumber: formState.values?.applicant?.phoneNumber,
        }),
      };
    } else {
      submitError = {
        id: error.message.id,
        message: {
          message: error.message,
          values: error.values,
        },
      };
    }
  } else if (syncErrors && submitFailed) {
    submitError = {
      id: messages.generalValidationError.id,
      message: formatMessage(messages.generalValidationError),
    };
  } else if (!syncErrors && submitFailed) {
    submitError = generalSubmitError;
  }

  const [scrolledError, setScrolledError] = useState(false);

  useEffect(() => {
    setScrolledError(submitError?.id);
    if (submitError && scrolledError !== submitError.id) {
      const errorElement = getElementOffsetPosition(window.document.querySelector('#error,#neutral'));
      window.scrollTo({
        top: errorElement.top - 175,
      });
    }
  }, [submitError, scrolledError]);

  if (submitError?.id && submitFailed && !submitting && !isLatestEventLabel(submitError.id)) {
    sendEvent({
      event: 'Error',
      eventCategory: 'form-interaction',
      eventAction: 'submit-failure-submit-button',
      eventLabel: submitError.id,
    });
  }

  const sendPhoneNumber = (phoneNumber) => {
    emailSubmissionSubmit(phoneNumber)
      .catch(() => {
        // throw new SubmissionError({ _error: 'Could not send phoneNumber' });
      });
  };

  return (
    <div
      className="sumbit-bar-anchor"
      onClick={() => (disableButton
        ? setShowErrors(true)
        : setShowErrors(false))}
      role="button"
      tabIndex={0}
    >
      <SubmitBarFigures />
      <TeleButton
        type="submit"
        className={classNames({ 'c-button--loading': submitting || submitSuccess },
        )}
        disabled={
        submitting
        || disableButton
        || submitSuccess
        || (
          !syncErrors
          && submitFailed
          && submitError?.id === data.messages.generalSubmitError.id
        ) || uncaughtRuntimeError
      }
      >
        {formatMessage(data.label)}
      </TeleButton>

      { submitError && (submitFailed || uncaughtRuntimeError) && !submitting && (
        <AlertWrapper>
          <Alert
            display={submitError.message}
            type={submitError.type}
          >
            {(submitError.id === messages.generalSubmitError.id
            || uncaughtRuntimeError) && (
            <>
              <TeleButton
                type="button"
                className={classNames({ 'c-button--loading': result?.isLoading },
                )}
                disabled={result?.isLoading || result?.isSuccess}
                onClick={() => sendPhoneNumber(formState.values.applicant?.phoneNumber)}
              >
                <FormattedMessage
                  id="applicationForm.emailSubmission"
                  defaultMessage="Email Submission"
                  description="'Email Submission'-button text on submit error in the form"
                />
              </TeleButton>
              {result?.isSuccess && (
                <EmailSubmissionSuccess>
                  <FormattedMessage
                    id="applicationForm.emailSubmission.successMessage"
                    defaultMessage="Thank you!"
                    description="Success message on the 'Email Submission'-button in the form"
                  />
                </EmailSubmissionSuccess>
              )}
              { result?.isError && (
                <EmailSubmissionSuccess>
                  <FormattedMessage
                    id="applicationForm.emailSubmission.errorMessage"
                    defaultMessage="We could not send your email adress, try again!"
                    description="Error message on the 'Email Submission'-button in the form"
                  />
                </EmailSubmissionSuccess>
              )}
            </>
            )}
          </Alert>
        </AlertWrapper>
      )}
    </div>
  );
};

SubmitButton.propTypes = {
  intl: intlShape.isRequired,
  data: PropTypes.shape().isRequired,
  submitSuccess: PropTypes.bool,
  emailSubmissionState: PropTypes.shape({
    submitting: PropTypes.bool,
    submitSuccess: PropTypes.bool,
    submitted: PropTypes.bool,
  }),
  disableButton: PropTypes.bool,
  setShowErrors: PropTypes.func,
};

SubmitButton.defaultProps = {
  submitSuccess: false,
  emailSubmissionState: null,
  disableButton: false,
  setShowErrors: () => {},
};

const SubmitButtonWithIntl = injectIntl(SubmitButton);

export { SubmitButtonWithIntl as SubmitButton };

export default (SubmitButtonWithIntl);
