import React from 'react';
import { Provider } from 'react-redux';

import { WalletProvider } from '@advisa/wallet';
import { MantineProvider } from '@mantine/core';
import { ViewportProvider } from './viewport';

import appPackage from '../../../package';

import { FloatingFormProvider } from '../../context/floating-form';
import store from '../store';
import ErrorBoundary from './error-boundary';
import initLogging from '../../../sentry-config';

import '@mantine/core/styles.css';

import { brand as importedBrand } from '../localizations/current-locale';

// This code block will be used until we resolve LP brand
let brand = importedBrand;
if (brand === 'lanaPengarna') {
  brand = 'advisa';
}

const capitalizedBrand = brand.charAt(0).toUpperCase() + brand.slice(1);

const appVersion = appPackage.version;

initLogging(appVersion);

// eslint-disable-next-line react/prop-types
const wrapWithProvider = () => ({ element }) => (
  <MantineProvider>
    <WalletProvider brand={capitalizedBrand}>
      <ViewportProvider>
        <FloatingFormProvider>
          <Provider store={store}>
            <ErrorBoundary>
              {element}
            </ErrorBoundary>
          </Provider>
        </FloatingFormProvider>
      </ViewportProvider>
    </WalletProvider>
  </MantineProvider>
);
export default wrapWithProvider;
